import { listingFields } from '../../../../config/configListing';

const optionToPriceData = option => {
  if (option.price === 'custom') {
    return {
      label: `Price for ${option.label} per night`,
      priceAmount: 'custom',
      id: `price_${option.option}`,
    };
  } else {
    return {
      label: `Price for ${option.label} per night`,
      priceAmount: option.price,
      id: `price_${option.option}`,
    };
  }
};

export const getCustomPrices = (publicData = {}) => {
  const pricesData = [];

  const category = publicData.category;

  const humanSpaceOptions = listingFields?.find(f => f?.key === 'humanSpace')?.enumOptions;
  const horseSpaceOptions = listingFields?.find(f => f?.key === 'horseSpace')?.enumOptions;
  const humanSpace = publicData.humanSpace || [];
  const horseSpace = publicData.horseSpace || [];

  if (category === 'horseSpace') {
    horseSpace.forEach(hs => {
      const foundOption = horseSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  } else if (category === 'humanSpace') {
    humanSpace.forEach(hs => {
      const foundOption = humanSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  } else if (category === 'horsesAndHumans') {
    humanSpace.forEach(hs => {
      const foundOption = humanSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
    horseSpace.forEach(hs => {
      const foundOption = horseSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  }

  return pricesData;
};

export const getTotalPriceAmount = publicData => {
  const priceKeys = [];
  let finalPriceAmount = 0;
  const allKeys = Object.keys(publicData);
  allKeys.forEach(k => {
    if (k.includes('price_')) {
      priceKeys.push(k);
    }
  });

  priceKeys.forEach(pKey => {
    finalPriceAmount += publicData[pKey]?.amount || publicData[pKey];
  });
  return finalPriceAmount;
};
