import { listingFields } from '../config/configListing';
import { getCustomPrices } from '../containers/EditListingPage/EditListingWizard/EditListingPricingPanel/utils';

const optionToPriceData = option => {
  if (option.price === 'custom') {
    return {
      label: `${option.label}`,
      priceAmount: 'custom',
      id: `price_${option.option}`,
    };
  } else {
    return {
      label: `${option.label}`,
      priceAmount: option.price,
      id: `price_${option.option}`,
    };
  }
};

const getPrices = (publicData = {}) => {
  const pricesData = [];

  const category = publicData.category;

  const humanSpaceOptions = listingFields?.find(f => f?.key === 'humanSpace')?.enumOptions;
  const horseSpaceOptions = listingFields?.find(f => f?.key === 'horseSpace')?.enumOptions;
  const humanSpace = publicData.humanSpace || [];
  const horseSpace = publicData.horseSpace || [];

  if (category === 'horseSpace') {
    horseSpace.forEach(hs => {
      const foundOption = horseSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  } else if (category === 'humanSpace') {
    humanSpace.forEach(hs => {
      const foundOption = humanSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  } else if (category === 'horsesAndHumans') {
    humanSpace.forEach(hs => {
      const foundOption = humanSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
    horseSpace.forEach(hs => {
      const foundOption = horseSpaceOptions.find(o => o.option === hs);
      const priceItem = optionToPriceData(foundOption);
      pricesData.push(priceItem);
    });
  }

  return pricesData;
};

export const getMinPriceAmountOfListing = listing => {
  const publicData = listing?.attributes?.publicData || {};
  const priceKeys = [];
  const allKeys = Object.keys(publicData);
  allKeys.forEach(k => {
    if (k.includes('price_')) {
      priceKeys.push(k);
    }
  });

  let minPriceAmount = listing?.attributes?.price?.amount;
  priceKeys.forEach(pKey => {
    const currentAmount = publicData[pKey]?.amount || publicData[pKey];
    if (currentAmount < minPriceAmount) {
      minPriceAmount = currentAmount;
    }
  });

  return minPriceAmount;
};

export const getSpacesOptions = listing => {
  const publicData = listing?.attributes?.publicData || {};
  const priceOptions = getPrices(publicData);
  const customPrices = getCustomPrices(publicData);

  const priceKeys = [];
  const allKeys = Object.keys(publicData);
  allKeys.forEach(k => {
    if (k.includes('price_')) {
      priceKeys.push(k);
    }
  });

  const predefinedPrices = customPrices.filter(p => !allKeys.includes(p.id));
  predefinedPrices.forEach(p => priceKeys.push(p.id));

  const options = priceKeys.map(k => {
    const label = priceOptions.find(o => o.id === k)?.label;
    const priceAmount =
      publicData[k]?.amount || publicData[k] || priceOptions?.find(x => x?.id === k)?.priceAmount;
    const priceLabel = ` $${priceAmount / 100}`;

    if (typeof priceAmount !== 'number') {
      return null;
    }

    // check if label is undefined
    if (!label) {
      return null;
    }
    
    return {
      label: label + priceLabel + ' per night',
      key: k,
    };
  });

  const filteredOptions = options.filter(o => o);
  return filteredOptions;
};
